import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar() {

  const navList = [
    { "text": "ホーム", "link": "/" },
    { "text": "みんなの結果", "link": "/tickets" },
    { "text": "ランキング", "link": "/ranking" },
    { "text": "馬券購入", "link": "/tickets/buy" },
    { "text": "アーカイブ", "link": "/archives" },
    { "text": "G1一覧", "link": "/races" },
    { "text": "馬券削除", "link": "/tickets/list" },
  ]

  const subNavList = [
    { "text": "打ち上げ会場", "link": "/uchiage" },
    { "text": "点数計算機", "link": "https://www.jra.go.jp/kouza/useful/mark_form_tensu/" },
    { "text": "サラブレット講座", "link": "https://www.jra.go.jp/kouza/thoroughbred/" },
  ]
  const path = window.location.pathname

  return (
    <Navbar expand="lg" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/" style={{color: 'white'}}>迫真競馬部管理サイト</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            { navList.map((item, index) => {
              const color = item.link === path ? 'white' : 'gray'
              return (
                <Nav.Link key={index} href={item.link} style={{color: color}}>{item.text}</Nav.Link>
              )
            })}
            <NavDropdown title="お役立ちツール" id="basic-nav-dropdown">
            { subNavList.map((item, index) => {
              return (
                <NavDropdown.Item key={index} href={item.link}>{item.text}</NavDropdown.Item>
              )
            })}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;