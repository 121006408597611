import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { Routes, Route } from 'react-router-dom'
import Home from './routes/Home'
import Ticket from './routes/Ticket'
import Ranking from './routes/Ranking'
import Race from './routes/Race'
import Purchase from './routes/Purchase'
import Archive from './routes/Archive'
import DeleteTicket from './routes/DeleteTicket'
import Party from './routes/Party'
import NavBar from './components/Layouts/NavBar';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tickets" element={<Ticket />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/races" element={<Race />} />
        <Route path="/tickets/buy" element={<Purchase />} />
        <Route path="/archives" element={<Archive />} />
        <Route path="/tickets/list" element={<DeleteTicket />} />
        <Route path="/uchiage" element={<Party />} />
      </Routes>
    </div>
  );
}

export default App;
