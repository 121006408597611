import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

function TopImage() {

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const imageList = [
        { "src": "images/photo0000-4242.jpg" },
        { "src": "images/photo0000-4262.jpg" },
        { "src": "images/photo0000-4274.jpg" },
        { "src": "images/photo0000-4277.jpg" },
    ]
    const currentImage = imageList[getRandomInt(imageList.length)]

    return (
        <Container>
            <div>
                <Image src={currentImage.src} style={{ width: '100%', maxHeight: '480px' }} />
                <div style={{ textAlign: 'left', bottom: '170px', position: 'relative', left: '30px'}}>
                    <h2 style={{ color: 'white', fontSize: '48px', fontWeight: 'bold' }}>HAKUSHIN!! <br></br>KEIBA CLUB</h2>
                    <Button href="/ranking" style={{marginRight: '5px'}}>ランキングを見る</Button>
                    <Button href="/tickets/buy">購入金額を入力する</Button>
                </div>
            </div>
        </Container>
    )
}
export default TopImage;



