import Container from "react-bootstrap/esm/Container";

function HomeContent() {
    return (
        <Container>
            <div style={{ textAlign: 'center' }}>
                <h2 style={{ color: 'black', fontSize: '48px', fontWeight: 'bold' }}>HAKUSHIN!! <br></br>KEIBA CLUB</h2>
                <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>競馬を楽しむためのサービスです。</p>
                <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>競馬を楽しむためのサービスです。</p>
            </div>
            <div style={{ textAlign: 'center' }}>
                <h2 style={{ color: 'black', fontSize: '48px', fontWeight: 'bold' }}>HAKUSHIN!! <br></br>KEIBA CLUB</h2>
                <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>競馬を楽しむためのサービスです。</p>
                <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>競馬を楽しむためのサービスです。</p>
            </div>

            <div style={{ textAlign: 'center' }}>
                <h2 style={{ color: 'black', fontSize: '48px', fontWeight: 'bold' }}>HAKUSHIN!! <br></br>KEIBA CLUB</h2>
                <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>競馬を楽しむためのサービスです。</p>
                <p style={{ color: 'black', fontSize: '24px', fontWeight: 'bold' }}>競馬を楽しむためのサービスです。</p>
            </div>

        </Container>
    )
}
export default HomeContent;
