import Container from 'react-bootstrap/esm/Container';
import CardBox from './CardBox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function OtherContent() {
    const cardBoxList = [
        {
            src: "/images/content_box_keiba.jpeg",
            title: "JRA即PAT",
            text: "【公式】JRA即PAT",
            link: "https://www.ipat.jra.go.jp/",
            caption: "リアル投票する！"
        },
        {
            src: "/images/content_box_sheet.jpeg",
            title: "ネット競馬",
            text: "競馬情報を見よう",
            link: "https://race.netkeiba.com/top/?_q=top&_q=top&rf=navi",
            caption: "リアル投票する！"
        },
        {
            src: "images/content_box_shintakarajima.jpeg",
            title: "新宝島",
            text: "【公式】新宝島が流せます",
            link: "https://www.youtube.com/watch?v=5fGeQGpLRSs",
            caption: "丁寧に描く"
        }
    ]

    return (
        <Container >
            <Row style={{ justifyContent: 'center'}}>
                { cardBoxList.map((cardBox, index) => {
                    return (
                        <Col key={index} >
                            <CardBox
                                src={cardBox.src}
                                title={cardBox.title}
                                text={cardBox.text}
                                link={cardBox.link}
                                caption={cardBox.caption}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Container>
    );
}

export default OtherContent;