import React from 'react';
import Container from 'react-bootstrap/Container';
import TopImage from '../components/Home/TopImage';
import HomeContent from '../components/Home/HomeContent';
import OtherContent from '../components/Home/OtherContent';

function Home() {
    return (
        <Container>
            <TopImage />
            <HomeContent />
            <OtherContent />
        </Container>
    )
}

export default Home;