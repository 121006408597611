import React from 'react';
import NavBar from '../components/Layouts/NavBar';
import Container from 'react-bootstrap/Container';

function Home() {
    return (
        <Container>
        </Container>
    )
}

export default Home;