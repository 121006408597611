import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function CardBox({ src, title, text, link, caption }) {
    return (
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src={src} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{text}</Card.Text>
                <Button href={link} variant="primary">{caption}</Button>
            </Card.Body>
        </Card>
    );
}

export default CardBox;